import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Testimonials from "../components/Testimonials";

import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

const AutoplaySlider = withAutoplay(AwesomeSlider);

import {
  LockClosedIcon,
  CurrencyDollarIcon,
  PencilIcon,
  SparklesIcon,
  WrenchIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";

import {
  LinkButton,
  CTAAreYouReady,
  Layout,
  SEO,
  PressSimple,
  FeatureGridWithOffsetIcons,
  FeatureSolutions,
  FAQ,
} from "../components";

import { socialMedia } from "../config";
import Container from "../components/Container";

const benefits = [
  {
    name: "Nuestra trayectoria nos respalda",
    description:
      "Con más de 10 años de experiencia y el respaldo de instituciones como el Banco Interamericano de Desarrollo, Bright se consolida como la opción más segura y efectiva, eliminando riesgos y preocupaciones.",
    icon: SparklesIcon,
  },
  {
    name: "Propuesta a tu medida",
    description:
      "Desde mensualidades sin intereses para hogares y negocios hasta soluciones personalizadas para empresas industriales, nos ajustamos a tus necesidades con la seguridad y respaldo de Bright.",
    icon: PencilIcon,
  },
  {
    name: "Instalación incluida",
    description:
      "Nos aliamos con las mejores cuadrillas de instaladores en cada ciudad para asegurar que tu sistema esté en óptimas condiciones.",
    icon: WrenchIcon,
  },
  {
    name: "Trámites ante CFE",
    description:
      "Desde el papeleo hasta los permisos, nos encargaremos de todos los todos los aspectos para la instalación de tu sistema.",
    icon: NewspaperIcon,
  },
  {
    name: "Certidumbre total",
    description:
      "Olvídate de cargos ocultos, con nosotros siempre sabrás cuánto tienes que pagar.",
    icon: LockClosedIcon,
  },
  {
    name: "Beneficio Fiscal Inmediato",
    description:
      "Reduce tus costos operativos y mejora tu estrategia contable.",
    icon: CurrencyDollarIcon,
  },
];

const IndexPage = ({ data }) => {
  const faqs = data.allStrapiFaqs.edges;
  const metadata = data.site.siteMetadata;

  const findSocialMedia = (slug) => {
    const founded = socialMedia.find((element) => element.slug === slug);
    return founded.href;
  };

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: metadata.title,
    url: metadata.siteUrl,
    logo: metadata.image,
    sameAs: [
      findSocialMedia("facebook"),
      findSocialMedia("twitter"),
      findSocialMedia("instagram"),
      findSocialMedia("youtube"),
      findSocialMedia("linkedin"),
    ],
  };

  return (
    <Layout>
      <SEO
        title="Servicio de energía solar sin inversión"
        description="Servicio premium de energía solar sin inversión para hogares y negocios en México. La suscripción a Bright incluye instalación de paneles solares, mantenimiento, monitoreo y garantía de producción."
        schemaMarkup={schemaMarkup}
      />
      {/* Hero Start */}
      <Container className="py-8 bg-white">
        <div className="flex w-full lg:w-1/2 justify-center lg:justify-start items-center px-6 text-center lg:text-left">
          <div>
            <h1 className="text-3xl sm:text-2xl text-blue-500 font-bold">
              <span className="block">
                Compra tus paneles solares con Bright
              </span>
              <span className="text-3xl sm:text-4xl block text-yellow-500 mt-1 font-bold uppercase">
                ¡Y disfruta beneficios fiscales inmediatos!
              </span>
            </h1>
            <p className="mt-3 md:mt-5 text-lg md:text-lg text-gray-700">
              Reduce tu recibo de luz <b>hasta un 98%</b> y deduce el ISR en el
              primer año.
            </p>
            <div className="mt-8 overflow-hidden">
              <dl className="-mx-6 -mt-8 flex flex-wrap justify-center lg:justify-start">
                <div className="flex flex-col px-6 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +40,000
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    kW instalados
                  </dt>
                </div>
                <div className="flex flex-col px-6 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +6,000
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Clientes
                  </dt>
                </div>
                <div className="flex flex-col px-6 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +20
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Estados
                  </dt>
                </div>
                <div className="flex flex-col px-6 pt-8">
                  <dd className="order-1 text-2xl font-bold text-blue-500 sm:text-3xl">
                    +60
                  </dd>
                  <dt className="order-2 text-base font-medium text-gray-700">
                    Ciudades
                  </dt>
                </div>
              </dl>
            </div>
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center lg:justify-start space-y-6 md:space-y-0 md:space-x-6">
              <LinkButton shadow arrow size="large" to="/cotizar">
                Solicita tu cotización
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex w-full lg:w-1/2 items-center px-8 mt-8 lg:mt-0 -z-0">
          <AutoplaySlider
            play={true}
            cancelOnInteraction={false} // should stop playing on user interaction
            interval={3000}
            bullets={false}
            organicArrows={false}
            infinite={true}
            mobileTouch={true}
          >
            <div>
              <StaticImage
                alt="instalacion montañas"
                height={450}
                src="../images/instalaciones-1.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion campo"
                height={450}
                src="../images/instalaciones-2.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion con flores"
                height={450}
                src="../images/instalaciones-3.jpg"
              />
            </div>
            <div>
              <StaticImage
                alt="instalacion soleada"
                height={450}
                src="../images/instalaciones-4.jpg"
              />
            </div>
          </AutoplaySlider>
        </div>
      </Container>
      {/* Hero End */}

      {/* Industry leaders*/}
      <Container className="py-8 bg-gray-50">
        <div className="flex flex-col mx-auto justify-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <div className="mx-auto px-8 text-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
            <h2 className="text-3xl font-bold text-blue-500">
              Los líderes de la industria confían en nosotros
            </h2>

            <div className="mt-20">
              <div className="grid grid-cols-1 gap-x-4 gap-y-16 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 ">
                <div className="rounded-md px-3 pb-8 border border-gray-100 shadow-md">
                  <StaticImage
                    alt="Casa con paneles solares con persona alcanzado el sol"
                    height={450}
                    src="../images/industrial-club-de-golf.png"
                  />
                  <p className="text-lg font-bold text-gray-700 mt-2">
                    Club de Golf la Hacienda
                  </p>
                  <p className="text-md text-gray-700 mt-2">
                    Atizapán de Zaragoza, México
                  </p>
                </div>
                <div className="rounded-md px-3 pb-8 border border-gray-100 shadow-md">
                  <StaticImage
                    alt="Casa con paneles solares con persona alcanzado el sol"
                    height={450}
                    src="../images/industrial-frizajal-2.png"
                  />
                  <p className="text-lg font-bold text-gray-700 mt-2">
                    Frizajal
                  </p>
                  <p className="text-md text-gray-700 mt-2">Zapopan, Jalisco</p>
                </div>
                <div className="rounded-md px-3 pb-8 border border-gray-100 shadow-md">
                  <StaticImage
                    alt="Casa con paneles solares con persona alcanzado el sol"
                    height={450}
                    src="../images/industrial-grupo-malaga-2.png"
                  />
                  <p className="text-lg font-bold text-gray-700 mt-2">
                    Grupo Málaga del Bajío
                  </p>
                  <p className="text-md text-gray-700 mt-2">
                    Querétaro, Querétaro
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      {/* Industry Leaders End */}

      {/* Why Bright Start */}
      <FeatureGridWithOffsetIcons
        title="¿Por qué elegir Bright?"
        description="Al suscribirte a nuestro Servicio Premium, obtienes:"
        features={benefits}
      />
      {/* Why Bright End */}

      {/* Who are we Start */}
      <Container className="py-16 bg-gray-50">
        <div className=" w-full  px-8 text-center">
          <div>
            <h2 className="mt-8 md:mt-5 text-3xl text-blue-500 font-bold justify-center">
              ¿Quiénes somos?
            </h2>
            <p className="mt-5 md:mt-5 text-lg text-gray-700">
              Somos la compañía de generación distribuida más grande de México,
              comprometida con la sostenibilidad y la eficiencia energética en
              el sector industrial.
            </p>
            <p className="mt-5 md:mt-5 text-lg text-gray-700">
              Contamos con el respaldo de instituciones líderes como el Banco
              Interamericano de Desarrollo (BID), El Fondo Danés de Inversión y
              figuras emblemáticas como Daniel Servitje (CEO de Grupo Bimbo) y
              JB Straubel (cofundador de Tesla) entre otros.
            </p>
            <div className="grid grid-cols-4 gap-x-2 gap-y-16 mt-8 justify-start">
              <div>
                <StaticImage
                  alt="Casa con paneles solares con persona alcanzado el sol"
                  height={200}
                  src="../images/logos/bid-logo.png"
                  className="rounded-full shadow-md"
                />
              </div>
              <div>
                <StaticImage
                  alt="Casa con paneles solares con persona alcanzado el sol"
                  height={200}
                  src="../images/logos/ifu-logo.png"
                  className="rounded-full shadow-md"
                />
              </div>
              <div>
                <StaticImage
                  alt="Casa con paneles solares con persona alcanzado el sol"
                  height={200}
                  src="../images/logos/daniel-servitje.png"
                  className="rounded-full shadow-md"
                />
              </div>
              <div>
                <StaticImage
                  alt="Casa con paneles solares con persona alcanzado el sol"
                  height={200}
                  src="../images/logos/jb-straubel.png"
                  className="rounded-full shadow-md"
                />
              </div>
            </div>
            <div className="flex mt-8 justify-center">
              <LinkButton outlined arrow to="/nosotros">
                Más acerca de Bright
              </LinkButton>
            </div>
          </div>
        </div>
      </Container>
      {/* Who are we End */}

      <Container className="py-16 bg-white">
        <div className="flex flex-col">
          <h2 className="mt-2 text-3xl font-bold text-blue-500 text-center uppercase">
            ¿Qué dicen nuestros clientes?
          </h2>
          <Testimonials />
        </div>
      </Container>

      <PressSimple background="bg-gray-50" />

      <div className="bg-white-50">
        <div className="flex flex-col mx-auto py-16 justify-center max-w-2xl md:max-w-3xl lg:max-w-5xl xl:max-w-7xl">
          <h2 className="text-3xl font-bold text-blue-500 text-center uppercase">
            Preguntas frecuentes
          </h2>
          <div className="mx-auto py-12 px-8 max-w-2xl flex-col space-y-6">
            {faqs.map((faq, index) => (
              <FAQ key={index} faq={faq.node} />
            ))}
          </div>
          <div className="mx-auto px-8 max-w-2xl">
            <LinkButton outlined arrow to="/preguntas-frecuentes">
              Ver más
            </LinkButton>
          </div>
        </div>
      </div>

      <CTAAreYouReady />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    allStrapiFaqs(filter: { display_on_homepage: { eq: true } }) {
      edges {
        node {
          question
          answer
        }
      }
    }
    site {
      siteMetadata {
        title
        defaultImage: image
        siteUrl
      }
    }
  }
`;
